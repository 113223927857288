import LocationOnIcon from "@mui/icons-material/LocationOn"
import { Box, useMediaQuery } from "@mui/material"
// import worker from "maplibre-gl/dist/maplibre-gl-csp-worker.js?url"
import "maplibre-gl/dist/maplibre-gl.css"
import { useEffect, useRef } from "react"
import { GeolocateControl, Marker, NavigationControl, MapEvent } from "react-map-gl/maplibre"
import type { MapInstance, MapRef } from "react-map-gl/maplibre"
import Map from "react-map-gl/maplibre"
import { isDefined, isPresent } from "ts-is-present"
import { Charger, DefaultApi as EvApi, Configuration as EvApiConf } from "../../out/gen/openapi/ev"
import {
   BoundingBox,
   Charging,
   EnergyStatus,
   GeoPos,
   DefaultApi as RoutePlannerApi,
   Configuration as RoutePlannerApiConf,
   RouteResult,
   RouteRequestUserBlockages,
   RoutePreference,
} from "../../out/gen/openapi/routeplanner"
import { CognitoUser } from "amazon-cognito-identity-js"
import useStore, { RouteCalculationSetting, VehicleEnergyStatus, VehicleParameterExt, Waypoint } from "../Store"
import { theme } from "../Theme"
import { MapLayerControl } from "../components/MapLayerControl"
import { ChargerInfoPopup } from "../components/charger/ChargerInfoPopup"
import { ChargerLayer, chargerPointLayerId } from "../components/charger/ChargerLayer"
import { Vehicle } from "../components/charger/PredefinedVehicles"
import { StopoverMarkers } from "../components/charger/StopoverMarker"
import { LocationSelectPopup } from "../components/itinerary/LocationSelectPopup"
import { RouteLineDisplay } from "../components/routeDisplay/RouteLineDisplay"
import { UserBlockageDisplay } from "../components/routeDisplay/UserBlockageDisplay"
import {
   RouteLinksLayer,
   RouteLinksLayer_LinkIdLayerId,
   RouteLinksLayer_MarkerLayerId,
} from "../components/routeDisplay/RouteLinksLayer"
import { TrafficEventLayer, TrafficEventLayer_EventPositionLayerId } from "../components/routeDisplay/TrafficEventLayer"
import { RouteInputContainer } from "../components/routeInput/RouteInputContainer"
import { WaypointMarkers } from "../components/routeInput/WaypointMarkers"
import { TiDisplay } from "../components/traffic/TrafficDisplay"
import CustomIcons from "../images/CustomIcons"
import { Auth } from "aws-amplify"
import { TerrainLayer } from "../components/TerrainLayer"
import { toLinkPath } from "../components/routeDisplay/UserBlockageDisplay"
import { functions } from "lodash"
import { Position } from "geojson"
import { toGeoJSON } from "@mapbox/polyline"

const routePlannerApi = new RoutePlannerApi(
   new RoutePlannerApiConf({
      apiKey: "AsYh7X0x3IaRqAIlpyJ4o85OQBR720UQ3SWGNuA1",
   }),
)
const evApi = new EvApi(
   new EvApiConf({
      apiKey: "AsYh7X0x3IaRqAIlpyJ4o85OQBR720UQ3SWGNuA1",
   }),
)

let routePostController: AbortController | null = null

async function triggerRouteCalculation(
   vehicle: Vehicle,
   vehicleParameter: VehicleParameterExt,
   charging: Charging,
   vehicleEnergyStatus: VehicleEnergyStatus,
   routeCalculationSetting: RouteCalculationSetting,
   start: Waypoint,
   waypoints: Waypoint[],
   routePreferences: RoutePreference[],
   userBlockages?: RouteRequestUserBlockages,
): Promise<RouteResult> {
   routePostController?.abort("Next RoutePost request issues.")
   routePostController = new AbortController()

   const numRouteAlternatives = 3

   const maxEnergy = vehicleParameter.max_energy_kWh

   const energyStatus: EnergyStatus | undefined =
      vehicle.type === "Diesel"
         ? undefined
         : {
              min_energy_at_destination_percent: vehicleEnergyStatus.min_energy_at_destination_percent,
              min_energy_at_stopover_percent: vehicleEnergyStatus.min_energy_at_stopover_percent,
              max_energy_percent: vehicleEnergyStatus.max_energy_percent,
              remaining_energy_kWh: (vehicleEnergyStatus.remaining_energy_percent * maxEnergy) / 100,
              max_energy_kWh: maxEnergy,
              other_devices_power_consumption_kW: vehicleEnergyStatus.other_devices_power_consumption_kW,
           }

   const usedVehicleParameter =
      vehicle.type === "EV" && vehicleParameter.consumption_speed_curve.length > 0
         ? { ...vehicleParameter, electric_drivetrain: true }
         : undefined

   const usedCharging = charging.charging_curve.length > 0 ? charging : undefined

   try {
      const user = await Auth.currentAuthenticatedUser()
      let username = undefined
      if (user instanceof CognitoUser) {
         username = user.getUsername()
      }
      const res = await routePlannerApi.routePost(
         {
            car_model: vehicle.type, // shortcut, modify once there are more models available on the server
            vehicle_parameter: usedVehicleParameter,
            charging: usedCharging,
            energy_status: energyStatus,
            use_ch_routing_algo: routeCalculationSetting.useChAlgo,
            use_traffic_realtime: routeCalculationSetting.useTrafficRealtime,
            want_maneuvers: routeCalculationSetting.wantManeuvers,
            curvy_route: routeCalculationSetting.curvyRoute,
            startOfTrip: start.pos ?? { lon: 0, lat: 0 },
            waypoints: waypoints.map(it => it.pos ?? { lon: 0, lat: 0 }),
            user_blockages: userBlockages,
            route_preference: routePreferences,
            max_routes: routeCalculationSetting.useAlternatives ? numRouteAlternatives : 1,
            with_route_links: routeCalculationSetting.withRouteLinks,
         },
         {
            signal: routePostController.signal,
            headers: {
               Authorization: username,
            },
         },
      )
      return res.data
   } catch (error) {
      return Promise.reject(error)
   }
}

let facilityGetController: AbortController | null = null

export async function triggerGetChargers(facilityIds: number[]): Promise<Charger[]> {
   facilityGetController?.abort("Next FacilityGet request issues.")

   if (facilityIds.length === 0) {
      facilityGetController = null
      return []
   }

   facilityGetController = new AbortController()
   const user = await Auth.currentAuthenticatedUser()
   let username = undefined
   if (user instanceof CognitoUser) {
      username = user.getUsername()
   }
   const res = await evApi.chargerGet(facilityIds, "normal", {
      signal: facilityGetController.signal,
      headers: {
         Authorization: username,
      },
   })
   if (Array.isArray(res.data)) {
      return res.data
   } else {
      console.warn("unexpected result type")
      return []
   }
}

function updateBounds(map: MapInstance, bounds: BoundingBox[], isMobile: boolean = false) {
   // Don't care if a value is north-west or south-east - just consider the actual values.
   const latValues = [...bounds.map(it => it.nw.lat), ...bounds.map(it => it.se.lat)]
   const lonValues = [...bounds.map(it => it.nw.lon), ...bounds.map(it => it.se.lon)]

   const p1: GeoPos = { lat: Math.max(...latValues), lon: Math.min(...lonValues) }
   const p2: GeoPos = { lat: Math.min(...latValues), lon: Math.max(...lonValues) }

   map.fitBounds([p1, p2], {
      padding: {
         left: isMobile ? 40 : 396 + 40, // in desktop layout, route shall not be below RouteInput container is on the left
         right: 40,
         top: 40,
         bottom: isMobile ? 40 + 40 : 40, // in mobile layout, route shall not be below map copyright notice
      },
   })
}

const RoutingPage = () => {
   const vehicle = useStore(store => store.vehicle)
   const vehicleParameter = useStore(store => store.vehicleParameter)
   const charging = useStore(store => store.charging)
   const energyStatus = useStore(store => store.energyStatus)
   const routeCalculationSetting = useStore(state => state.routeCalculationSetting)
   const start = useStore(state => state.start)
   const updateStart = useStore(state => state.updateStart)
   const dest = useStore(state => state.dest)
   const routePreferences = useStore(s => s.routePreference)
   const userBlockages = useStore(s => s.userBlockages)
   const routeResult = useStore(state => state.routeResult)
   const setRouteResult = useStore(state => state.setRouteResult)
   const clearRouteResult = useStore(state => state.clearRouteResult)
   const updateCalculationStatus = useStore(state => state.updateCalculationStatus)
   const locationSelectPopupProps = useStore(state => state.locationSelectPopupProps)
   const updateLocationSelectPopupProps = useStore(state => state.updateLocationSelectPopupProps)
   const updateChargersOnCurrentRouteResult = useStore(state => state.updateChargersOnCurrentRouteResult)
   const updateSelectedCharger = useStore(state => state.updateSelectedCharger)
   const updateActiveTrafficEventId = useStore(state => state.updateActiveTrafficEventId)
   const trafficLayerEnabled = useStore(store => store.trafficLayerEnabled)
   const chargerLayerEnabled = useStore(store => store.chargerLayerEnabled)
   const terrainLayerEnabled = useStore(store => store.terrainLayerEnabled)
   const tileBoundaryLayerEnabled = useStore(store => store.tileBoundaryLayerEnabled)
   const collisionBoxesLayerEnabled = useStore(store => store.collisionBoxesLayerEnabled)
   const overdrawInspectorLayerEnabled = useStore(store => store.overdrawInspectorLayerEnabled)

   const mapRef = useRef<MapRef>(null)

   const isMobile = useMediaQuery(theme.breakpoints.down("md"))

   let currentRouteCalc: Promise<void> = Promise.resolve()

   let currentLongPressTimer = setTimeout(() => {}, 0)

   useEffect(() => {
      if (!start.pos) return
      if (!dest.length) return
      if (dest.filter(it => it.pos === undefined).length) return
      clearRouteResult()

      const userBlockagePaths =
         userBlockages?.length > 0 ? { blocked_paths: userBlockages.map(b => toLinkPath(b)) } : undefined

      currentRouteCalc = currentRouteCalc
         .then(() => {
            updateCalculationStatus("pending")
            // Hide a possible selected charger when a new route result will be displayed
            updateSelectedCharger(null)
            return triggerRouteCalculation(
               vehicle,
               vehicleParameter,
               charging,
               energyStatus,
               routeCalculationSetting,
               start,
               dest,
               routePreferences,
               userBlockagePaths,
            )
         })
         .then(routeRes => {
            updateCalculationStatus("finished")
            setRouteResult(routeRes)
            // TODO move actions to happen upon new route result into effect
            const map = mapRef.current?.getMap()
            if (map) {
               updateBounds(
                  map,
                  routeRes.routes.map(it => it.bounds),
                  isMobile,
               )
            }
         })
         .catch(error => {
            if (error.code === "ERR_CANCELED") {
               // In case of Cancellation, we must update the status manually where the calculation is issued.
               // Otherwise, the update in this cancellation handler (exception) has race condition with
               // possible updates of the status at other places, e.g. because a new route calc is already issued.
               console.info("Route calculation cancelled")
            } else {
               updateCalculationStatus("finished")
               console.error("Route calculation failed", error)
            }
         })
   }, [
      start,
      dest,
      routePreferences,
      userBlockages,
      vehicle,
      vehicleParameter,
      charging,
      energyStatus,
      routeCalculationSetting,
   ])

   useEffect(() => {
      if (routeResult?.result.value === "Success") {
         const facilityIds: number[] = routeResult.routes
            .flatMap(route => route.segments)
            .filter(isDefined)
            .flatMap(segment => segment.automatic_stopovers)
            .filter(isDefined)
            .map(stopover => stopover.facility_id)
            .filter(isDefined)

         if (facilityIds.length) {
            triggerGetChargers(facilityIds)
               .then(res => {
                  updateChargersOnCurrentRouteResult(res)
               })
               .catch(error => {
                  if (error.code === "ERR_CANCELED") {
                     console.info("ChargersGet calculation cancelled")
                  } else {
                     updateCalculationStatus("finished")
                     console.error("ChargersGet failed", error)
                  }
               })
         }
      }
   }, [routeResult])

   const routeColorActive = theme.palette.primary.light
   const routeColorInactive = theme.palette.grey[500]

   async function onClickHandler(e: maplibregl.MapLayerMouseEvent) {
      // For chargers
      if (e.target.getLayer(chargerPointLayerId) !== undefined) {
         // need to use query instead of interactiveLayerIds, because ILI breaks onContextMenu, except on marked layers
         const features = e.target.queryRenderedFeatures(e.point, {
            layers: [chargerPointLayerId],
         })
         if (features !== undefined && features?.length > 0) {
            const ids = features
               .map(f => {
                  if (f.properties?.id !== undefined && typeof f.properties.id === "number") {
                     return f.properties?.id
                  } else {
                     return undefined
                  }
               })
               .filter(isPresent)

            if (ids.length === 0) return

            const res = await evApi.chargerGet(ids)
            if (Array.isArray(res.data)) {
               res.data.sort((a, b) => (a.priority ?? 0) - (b.priority ?? 0))
               updateSelectedCharger({ stopover: undefined, charger: res.data[0] })
            }
            return
         }
      }

      // For RoutingLinks
      if (
         e.target.getLayer(RouteLinksLayer_MarkerLayerId) !== undefined &&
         e.target.getLayer(RouteLinksLayer_LinkIdLayerId) !== undefined
      ) {
         const features = e.target.queryRenderedFeatures(e.point, {
            layers: [RouteLinksLayer_MarkerLayerId, RouteLinksLayer_LinkIdLayerId],
         })
         if (typeof features[0]?.properties?.id === "string") {
            console.log("Copied to clipboard: " + features[0].properties?.id)
            navigator.clipboard.writeText(features[0].properties?.id).catch(console.error)
            return
         }
      }

      // For Traffic Events
      if (e.target.getLayer(TrafficEventLayer_EventPositionLayerId) !== undefined) {
         const features = e.target.queryRenderedFeatures(e.point, {
            layers: [TrafficEventLayer_EventPositionLayerId],
         })
         if (typeof features[0]?.properties?.msgId === "number") {
            console.log("activeTrafficEventId: " + features[0].properties?.msgId)
            updateActiveTrafficEventId(features[0].properties?.msgId)
         } else {
            updateActiveTrafficEventId(-1)
         }
      }
   }

   function onMouseMove(e: maplibregl.MapLayerMouseEvent) {
      // For switching the mouse pointer
      {
         const layers = []
         if (e.target.getLayer(chargerPointLayerId) !== undefined) layers.push(chargerPointLayerId)
         if (e.target.getLayer(RouteLinksLayer_LinkIdLayerId) !== undefined) layers.push(RouteLinksLayer_LinkIdLayerId)
         if (e.target.getLayer(RouteLinksLayer_MarkerLayerId) !== undefined) layers.push(RouteLinksLayer_MarkerLayerId)
         if (e.target.getLayer(TrafficEventLayer_EventPositionLayerId) !== undefined)
            layers.push(TrafficEventLayer_EventPositionLayerId)
         const features = e.target.queryRenderedFeatures(e.point, { layers })

         const map = e.target
         const canvas = map.getCanvasContainer()
         canvas.style.cursor = features.length ? "pointer" : ""
      }
   }

   function onMapLoad(e: MapEvent) {
      // Load our custom images
      CustomIcons.forEach(icon => {
         const customIcon = new Image(24, 24)
         customIcon.onload = () => mapRef.current?.addImage(icon.name, customIcon)
         customIcon.src = icon.src
      })
      const spriteUrl = new URL("/assets/map/style/sprite", document.baseURI)
      e.target.setSprite(spriteUrl.href)
      e.target.on("styleimagemissing", e => {
         console.warn("styleimagemissing", e)
      })
   }

   function showLocationSelectPopup(lat: number, lng: number) {
      updateLocationSelectPopupProps({
         show: true,
         pos: { lat: lat, lon: lng },
      })
   }

   useEffect(() => {
      const map = mapRef.current?.getMap()
      if (map !== undefined) {
         map.showTileBoundaries = tileBoundaryLayerEnabled
         map.showCollisionBoxes = collisionBoxesLayerEnabled
         map.showOverdrawInspector = overdrawInspectorLayerEnabled
      }
   }, [tileBoundaryLayerEnabled, collisionBoxesLayerEnabled, overdrawInspectorLayerEnabled])

   return (
      <Map
         id="map"
         ref={mapRef}
         style={{ width: "100%", height: "100%", flexGrow: "1" }}
         mapStyle={"/assets/map/style/style.json"}
         initialViewState={{
            latitude: 53.595013,
            longitude: 9.945682,
            zoom: 14,
         }}
         // workerUrl={worker}
         onContextMenu={event => {
            showLocationSelectPopup(event.lngLat.lat, event.lngLat.lng)
         }}
         onTouchStart={event => {
            clearTimeout(currentLongPressTimer)
            currentLongPressTimer = setTimeout(() => {
               showLocationSelectPopup(event.lngLat.lat, event.lngLat.lng)
            }, 500)
         }}
         onTouchCancel={() => clearTimeout(currentLongPressTimer)}
         onTouchMove={() => clearTimeout(currentLongPressTimer)}
         onTouchEnd={event => {
            clearTimeout(currentLongPressTimer)
         }}
         onClick={e => onClickHandler(e)}
         onMouseMove={e => onMouseMove(e)}
         onLoad={onMapLoad}
      >
         {trafficLayerEnabled && <TiDisplay />}
         {chargerLayerEnabled && <ChargerLayer />}
         {terrainLayerEnabled && <TerrainLayer />}

         <NavigationControl visualizePitch showZoom showCompass position="bottom-right" />
         <GeolocateControl trackUserLocation />
         {start.pos && (
            <Marker
               latitude={start.pos.lat}
               longitude={start.pos.lon}
               draggable={true}
               onDragEnd={e => updateStart({ lat: e.lngLat.lat, lon: e.lngLat.lng })}
            >
               <Box
                  display={"flex"}
                  sx={{
                     "width": 32,
                     "height": 32,
                     "borderRadius": 4,
                     "border": 1,
                     "borderColor": "primary.dark",
                     "backgroundColor": "white",
                     "&:hover": {
                        backgroundColor: "primary.main",
                        opacity: [0.9, 0.8, 0.7],
                     },
                  }}
                  justifyContent={"center"}
                  alignItems={"center"}
                  onContextMenu={event => {
                     event.stopPropagation()
                     event.preventDefault()
                     updateLocationSelectPopupProps({ ...locationSelectPopupProps, show: false })
                  }}
               >
                  <LocationOnIcon color="info" />
               </Box>
            </Marker>
         )}
         <WaypointMarkers />
         <UserBlockageDisplay />
         <RouteLineDisplay routeColorActive={routeColorActive} routeColorInactive={routeColorInactive} />
         <RouteLinksLayer />
         <TrafficEventLayer />

         {/* <RouteOverviewContainer /> */}
         <RouteInputContainer routeColorActive={routeColorActive} routeColorInactive={routeColorInactive} />
         {locationSelectPopupProps.show && <LocationSelectPopup />}
         {locationSelectPopupProps.show && locationSelectPopupProps.waypointIdx === undefined && (
            <Marker longitude={locationSelectPopupProps.pos.lon} latitude={locationSelectPopupProps.pos.lat}>
               <LocationOnIcon color="info" />
            </Marker>
         )}
         <MapLayerControl />
         <StopoverMarkers />
         <ChargerInfoPopup />
      </Map>
   )
}

export default RoutingPage
